import { displayPriceWithCurrency } from "@shared/product/product.utils";
import { IProductCarousel } from "@shared/types/product.types";
import useLocale from "@hooks/useLocale";

import styles from "@components/Carousels/CarouselProductPrice/carousel-product-price.module.scss";

const CarouselProductPrice = ({ priceForFront }: Pick<IProductCarousel, "priceForFront">) => {
	const { locale } = useLocale();

	if (!priceForFront?.finalPrice) return null;

	return (
		<div className={styles.priceWrapper}>
			<p className={styles.price} data-testid="CarouselProductPrice_final-price">
				{displayPriceWithCurrency(priceForFront.finalPrice, locale)}
			</p>
			{Boolean(priceForFront.showSlash && priceForFront.slashedPrice) && (
				<p className={styles.slashedPrice} data-testid="CarouselProductPrice_slashed-price">
					{displayPriceWithCurrency(priceForFront.slashedPrice, locale)}
				</p>
			)}
		</div>
	);
};

export default CarouselProductPrice;
