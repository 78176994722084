import { EBlockTypenames, ICollection } from "@shared/contentful/contentful.types";
import { IAssetCflData } from "../ComponentAsset/type";
import { ICarouselItemCflData } from "../ComponentCarouselItem/type";

export enum EBlockCarouselSlidesPerView {
	MobileOne = "mobile: 1 / tablet: 1.25 / desktop: 1.5",
	MobileOnePointTwo = "mobile: 1.2 / tablet: 2.2 / desktop: 4.2",
	MobileOnePointFour = "mobile: 1.4 / tablet: 3 / desktop: 5.15",
}

export enum EBlockCarouselDisplayArrows {
	Over = "over",
	Before = "before",
}

export interface IBlockCarouselCflData {
	sys: { id: string };
	__typename: EBlockTypenames.Carousel;
	name: string;
	identifier?: string;
	title?: string;
	carouselItemsCollection: ICollection<ICarouselItemCflData | IAssetCflData>;
	slidesPerView: EBlockCarouselSlidesPerView;
	displayArrows: EBlockCarouselDisplayArrows;
}
