import { IProduct } from "@shared/types/product.types";

import styles from "@components/Carousels/CarouselProductNameBrand/carousel-product-name-brand.module.scss";

interface ICarouselProductNameBrand extends Pick<IProduct, "brand"> {
	wrapperClassName?: string;
	productName?: string;
}

const CarouselProductNameBrand = ({
	productName,
	brand,
	wrapperClassName = "",
}: ICarouselProductNameBrand) => {
	if (!productName && !brand) return null;

	return (
		<div className={wrapperClassName}>
			{productName && (
				<p className={styles.productName} data-testid="CarouselProductNameBrand_name">
					{productName}
				</p>
			)}
			{brand && (
				<p className={styles.brand} data-testid="CarouselProductNameBrand_brand">
					{brand.toLowerCase()}
				</p>
			)}
		</div>
	);
};

export default CarouselProductNameBrand;
