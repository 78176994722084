import React from "react";

import { EBLockCarouselIdentifiers } from "@components/ContentfulComponents/BlockCarousel/block-carousel.query";

import Icon, { EIcon } from "@components/Icon/Icon.component";

import styles from "./arrows.module.scss";

interface IProps {
	prevArrow: string;
	nextArrow: string;
	ariaLabels: Record<string, string>;
	title?: string;
}

const ArrowsBefore = ({ prevArrow, nextArrow, title, ariaLabels }: IProps) => {
	const prevArrowAriaLabel = ariaLabels[EBLockCarouselIdentifiers.AriaLabelPrevious] || "previous";
	const nextArrowAriaLabel = ariaLabels[EBLockCarouselIdentifiers.AriaLabelNext] || "next";
	return (
		<div className={styles.beforeWrapper} data-testid="ArrowsBefore_wrapper">
			{title && <h2>{title}</h2>}
			<div className={styles.beforeIcons}>
				<button
					className={prevArrow}
					aria-label={prevArrowAriaLabel}
					data-testid="ArrowsBefore_prev-arrow"
				>
					<Icon icon={EIcon.ChevronLeft} aria-label={prevArrowAriaLabel} />
				</button>
				<button
					className={nextArrow}
					aria-label={nextArrowAriaLabel}
					data-testid="ArrowsBefore_next-arrow"
				>
					<Icon icon={EIcon.ChevronRight} aria-label={nextArrowAriaLabel} />
				</button>
			</div>
		</div>
	);
};

export default ArrowsBefore;
