import { ReactNode, useEffect, useState } from "react";
import { Swiper } from "swiper/react";
import SwiperCore, { Scrollbar, Navigation } from "swiper";
import useGraphQL from "@hooks/useGraphql";
import { useSlidesPerView } from "@hooks/useSlidesPerView";
import ArrowsOver from "@components/Carousels/Arrows/ArrowsOver.component";
import ArrowsBefore from "@components/Carousels/Arrows/ArrowsBefore.component";
import {
	blockCarouselQuery,
	EBLockCarouselIdentifiers,
	IBlockCarouselA11yDataVariables,
	TBlockCarouselA11yData,
} from "@components/ContentfulComponents/BlockCarousel/block-carousel.query";
import { EBlockCarouselDisplayArrows } from "@components/ContentfulComponents/BlockCarousel/type";
import { EGridSize } from "@components/ContentfulComponents/ContentPagesContentBlock/type";
import { ISlidesPerView } from "@shared/types/carousel.types";
import { getContentTranslations } from "@shared/contentful/translations.utils";

import styles from "@components/Carousels/CarouselLayout/carousel-layout.module.scss";

export interface ICarouselLayout {
	gridSize: EGridSize;
	prevArrow: string;
	nextArrow: string;
	slidesPerView: ISlidesPerView;
	children: ReactNode;
	title?: string;
	displayArrows?: EBlockCarouselDisplayArrows;
	wrapperClassname?: string;
}

SwiperCore.use([Navigation, Scrollbar]);

const CarouselLayout = ({
	gridSize,
	prevArrow,
	nextArrow,
	slidesPerView,
	children,
	title,
	displayArrows = EBlockCarouselDisplayArrows.Before,
	wrapperClassname = "",
}: ICarouselLayout) => {
	const [a11yDataTranslation, setA11yDataTranslation] = useState<Record<string, string>>({});
	const { data: a11yData } = useGraphQL<TBlockCarouselA11yData, IBlockCarouselA11yDataVariables>(
		blockCarouselQuery,
		{
			variables: {
				identifier: EBLockCarouselIdentifiers.BlockCarouselA11yNavigation,
			},
		}
	);

	useEffect(() => {
		const content = a11yData?.contentBlockCollection?.items?.[0].contentCollection?.items;
		if (content) {
			setA11yDataTranslation(getContentTranslations<string>(content));
		}
	}, [a11yData]);

	const slidesPerScreen = useSlidesPerView(slidesPerView);

	return (
		<div
			className={`container-grid ${gridSize}-grid ${wrapperClassname}`}
			data-testid="CarouselLayout_wrapper"
		>
			<div className={styles.wrapper}>
				{displayArrows === EBlockCarouselDisplayArrows.Before ? (
					<ArrowsBefore
						prevArrow={prevArrow}
						nextArrow={nextArrow}
						title={title}
						ariaLabels={a11yDataTranslation}
					/>
				) : (
					<ArrowsOver
						prevArrow={prevArrow}
						nextArrow={nextArrow}
						ariaLabels={a11yDataTranslation}
					/>
				)}

				<div className={styles.slides}>
					<Swiper
						navigation={{
							prevEl: `.${prevArrow}`,
							nextEl: `.${nextArrow}`,
							disabledClass: styles.disabledArrow,
						}}
						noSwipingSelector="button"
						slidesPerView={slidesPerScreen ?? 4.2}
						allowTouchMove
						spaceBetween={16}
					>
						{children}
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default CarouselLayout;
