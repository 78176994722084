import { ISlidesPerView } from "@shared/types/carousel.types";
import { useResponsive } from "./useDevice";

export const useSlidesPerView = (data: ISlidesPerView) => {
	if (!data) return;
	const { isMobile, isTablet, isDesktop, isBigScreen } = useResponsive();
	const { mobile = 1, tablet = 1, desktop = 1 } = data;
	if (isDesktop || isBigScreen) return desktop;
	if (isTablet) return tablet;
	if (isMobile) return mobile;
};
